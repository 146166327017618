.modal {

  .modal-header {
    background-color: $body-bg;
    .modal-title {
      font-size: 1.1rem;
      font-weight: bold;
      text-transform: uppercase;
    }
  }

  .modal-footer {
    justify-content: space-between;
    background-color: $body-bg;
  }
  .test-connection {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    overflow: auto;
    margin-left: 25px;
  }
  .test-connection Button{
    margin: auto 8px;  
  }
  .file-import {
    border-radius: 2px;
    border: 1px dashed #ccd6e3;
    background-color: #f5f8fa;
    text-align: center;
    padding: $grid-gutter-width;
    height: 300px;
    position: relative;
    cursor: pointer;
    .form-container {
      position: static;
    }
    .file-upload {
      p {
        margin: 0;
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        text-align: center;
        transform: translate3d(0, -50%, 0);
      }
    }
  }

  .file-info {
    h5 {
      font-size: 0.9rem;
      margin: 0;
      text-transform: uppercase;
    }
    div {
      font-size: 0.9em;
      margin-bottom: $grid-gutter-width;
    }
    .btn {
      box-shadow: 0 1px 3px rgba(black, .2);
    }
  }

  &.modal-success {
    .modal-header {
      background-color: $success;
      .modal-title, .close {
        color: white;
      }
    }
  }

  &.modal-danger {
    .modal-header {
      background-color: $danger;
      .modal-title, .close {
        color: white;
      }
    }
  }

  &.modal-upload-products {
    .modal-body {
      min-height: 425px;
      .file-info {
        div {
          margin-bottom: 0;
        }
      }
      .modal-loading {
        padding-top: 8.5rem;
      }
      .results {
        max-width: 80%;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: $grid-gutter-width;
        margin-top: $grid-gutter-width / 2;
        .result {
          border-radius: 6px;
          background-color: #eee;
          text-align: center;
          padding: 1.55rem 0.5rem;
          span {
            font-size: 2rem;
            font-weight: bold;
          }
          strong {
            display: block;
            font-size: 0.9rem;
            opacity: .55;
          }
          &.red {
            color: darken($danger, 20%);
            background-color: mix($danger, #eee, 20%);
          }
          &.green {
            color: darken($success, 20%);
            background-color: mix($success, #eee, 20%);
          }
        }
      }
      .exceptions {
        max-width: 90%;
        border-radius: 6px;
        padding: 1rem;
        background-color: mix($danger, white, 7.5%);
        margin: 0 auto $grid-gutter-width / 2;
        border: 1px solid $danger;
        strong {
          font-size: 0.75rem;
          text-transform: uppercase;
          color: $danger;
          display: block;
          padding-bottom: 0.5rem;
        }
        div {
          font-size: 0.9rem;
          & + div {
            margin-top: $grid-gutter-width / 2;
            padding-top: $grid-gutter-width / 2;
            border-top: 1px solid darken($danger, 20%);
          }
        }
      }
      .success-message {
        text-align: center;
        padding-top: 8.5rem;
        .fa {
          color: $success;
          font-size: 5rem;
          display: block;
          margin-bottom: 15px;
        }
      }
    }
  }

}
