.form-group-numeral {
  position: relative;
  .symbols .form-group {
    width: 60px;
    position: absolute;
    left: 0;
    bottom: 0;
    margin: 0;
    z-index: 1;
  }
  .single .form-group {
    .form-container {
      margin-left: 58px;
    }
    .form-control {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
  .react-select__control {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-color: $input-border-color;
    cursor: pointer;
  }
  .react-select__indicators {
    display: none;
  }
  .react-select__dropdown-indicator {
    display: none;
    svg {
      display: none;
    }
  }
  .react-select__menu {
    font-size: 0.9rem;
  }
  .range {
    margin-bottom: 1rem;
    .form-group {
      width: 57%;
      display: inline-block;
      margin: 0;
      &:first-child {
        .form-container {
          margin-left: 58px;
          .form-control {
            border-radius: 0;
            border-right: 0;
          }
        }
      }
      & + .form-group {
        width: 43%;
        .form-control {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
        }
      }
    }
  }
}
