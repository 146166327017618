.tooltip {

    .tooltip-inner {
        text-align: left;
        max-width: 300px;
        border-radius: 0.3rem;
        background-color: #fff;
        color: rgba(148, 150, 166, 1);
        box-shadow: 1px 1px 4px 2px #cccccc, -1px -1px 4px 2px #cccccc;
        padding: 0.3rem 0.6rem;
        line-height: 1.25;

    }
}

.bs-tooltip-bottom {
    .arrow::before {
        border-bottom-color: #ffff;
    }
}