.form-group {
  .wysiwyg-toolbar {
    border: 1px solid $input-border-color;
    border-bottom: none;
    border-radius: $input-border-radius $input-border-radius 0 0;
    margin-bottom: 0;
    .rdw-embedded-modal,
    .rdw-link-modal {
      height: auto;
      .rdw-embedded-modal-btn-section,
      .rdw-link-modal-buttonsection {
        .rdw-embedded-modal-btn,
        .rdw-link-modal-btn {
          margin-left: 0;
          & + .rdw-embedded-modal-btn,
          & + .rdw-link-modal-btn {
            margin-left: 10px;
          }
          &:first-child {
            background-color: $primary;
            border-color: $primary;
            color: white;
            &:disabled {
              opacity: 0.65;
              pointer-events: none;
            }
          }
        }
      }
      .rdw-embedded-modal-btn-section {
        margin-top: 20px;
      }
      .rdw-embedded-modal-size-input,
      .rdw-embedded-modal-link-input,
      .rdw-link-modal-input {
        height: 32px;
        border-color: $input-border-color;
      }
    }
    .rdw-embedded-modal-header-option {
      width: 100%;
      align-items: flex-start;
      .rdw-embedded-modal-header-label {
        display: none;
      }
    }
  }
  .wysiwyg-editor {
    border: 1px solid $input-border-color;
    border-radius: 0 0 $input-border-radius $input-border-radius;
    padding: 0 0.75rem 0.375rem 0.75rem;
    min-height: 200px;
  }
}
