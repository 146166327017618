.layout {
  .layout-small-header {
    margin: 1rem 0 0.5rem;
    font-size: 1.25rem;
    font-weight: bold;
    text-transform: uppercase;
    color: $primary;
  }
}
.navbar.fixed-top + .layout {
  margin-top: $nav-link-height;
  padding-top: $grid-gutter-width;
}

.layout-header {
  background-color: $primary;
  padding-top: $grid-gutter-width;
  padding-bottom: $grid-gutter-width;
  margin-bottom: $grid-gutter-width;
  .breadcrumb {
    margin-bottom: 1.5rem;
  }
  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    h1 {
      margin: 0;
      color: white;
      font-size: 2rem;
      span {
        margin-right: 0.5rem;
      }
    }
    .btn {
      padding-left: 1.25rem;
      padding-right: 1.25rem;
      & + .btn,
      & + .dropdown {
        margin-left: 0.5rem;
      }
    }
    .btn-secondary {
      background-color: white;
      border-color: white;
      color: $primary;
    }
    .dropdown {
      display: inline-block;
    }
    .dropdown-item {
      cursor: pointer;
    }
  }

}
