.advanced-search {
  border: 1px solid #e7eaf3;
  box-shadow: 0 0.5rem 1.2rem rgba(189, 197, 209, 0.2);
  background-color: white;
  margin-bottom: $grid-gutter-width;
  border-radius: $border-radius;
  padding: $grid-gutter-width / 3;
  @include media-breakpoint-up(md) {
    padding: $grid-gutter-width / 2;
  }
  h3 {
    font-size: 1rem;
    text-transform: uppercase;
    opacity: 0.35;
  }
  hr {
    margin-top: 0;
  }
  .form-control {
    &:focus,
    &:active,
    &:active:focus {
      box-shadow: none;
      border-color: $primary;
    }
  }
}
