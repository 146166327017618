.breadcrumb {
  padding: 0;
  background-color: transparent;
  color: rgba(white, 0.5);
  font-size: 0.8rem;
  a {
    color: rgba(white, 0.5);
    transition: all .3s;
    &:hover {
      text-decoration: none;
      color: white;
    }
  }
}
