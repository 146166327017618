.metricsTile {
    border-radius: 12px;
    min-height: 180px;
    background-color: white;
    box-shadow: 0px 4px 4px 1px #cccccc;
    cursor: default;

    .title {
        padding-top: 1rem;
        padding-left: 1rem;
        font-weight: 700;
        color: rgba(148, 150, 166, 1)
    }


    .containerVertical {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;

        label {
            color: rgba(18, 22, 63, 1);
            font-size: 10pt;
            font-weight: bold;
            padding-left: 1.5rem;
            padding-right: 1.5rem;
            margin-top: 0.25rem;
        }
    }

    .iconContainer {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        margin-top: 0.25rem;

        .ruler {
            height: 2px;
            background-color: rgba(18, 22, 63, 1);
            flex: 0.5;
        }

        &.flex-end {
            justify-content: flex-end;
        }

        span {
            font-size: 7pt;
        }
    }

    .dataContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;

        :nth-child(1) {
            font-weight: 500;
            font-size: 36pt;
            color: #12173D;
        }

        :nth-child(2) {
            color: rgba(148, 150, 166, 1);
            margin-top: -10px;
            font-size: 10pt;
        }

        &.multi {
            :nth-child(1) {
                font-weight: 500;
                font-size: 24pt;
                color: #12173D
            }
        }

        &.mini {
            :nth-child(1) {
                font-weight: 500;
                font-size: 24pt;
                color: #12173D
            }

            :nth-child(2) {
                color: rgba(148, 150, 166, 1);
                margin-bottom: 6px;
                font-size: 8pt
            }
        }

        &.fixed-width {
            max-width: 60%;
        }
    }

    .contentDiv {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding-top: 1rem;

        &.multi {
            padding-top: 2rem;
            justify-content: space-around;
            padding-left: 2rem;
            padding-right: 2rem;
        }

        &.less-padding {
            padding-top: 1rem;
        }

        &.flex-start {
            justify-content: flex-start;
            align-self: center;
            padding-left: 2.25rem;
        }
    }

    .iconDiv {
        display: flex;
        justify-content: center;
        align-items: center;
        float: right;
        margin-right: 0.3rem;
        margin-top: 0.75rem;
        width: 24px;
        height: 24px;
        border-radius: 12px;
        border: 1px solid rgba(18, 22, 63, 0.3);
        color: rgba(18, 22, 63, 0.3);
        transform: scale(0.75);
        cursor: pointer;
        position: absolute;
        left: calc(100% - 42px);
        bottom: 2%;

        span {
            pointer-events: none;
        }
    }

    &.with-margin-top {
        margin-top: 2rem;
    }
}

.horizontalRuler {
    width: calc(100% - 30px);
    background-color: rgba(0, 0, 0, 0.1);
    height: 1px;
    margin-left: 15px;
    margin-top: 2rem;
    margin-bottom: 2rem;
}


.toggleButton {
    text-transform: none;
    background-color: white;
    color: #12163f;
    font-weight: 500;
    box-shadow: none !important;

    &.selected {
        background-color: #12163f;
        color: white
    }
}