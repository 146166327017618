.table-data {
  margin: 0;
  tbody {
    font-size: 0.9rem;
    & > tr {
      & > th {
        padding-left: 0;
        width: 40%;
      }
      td {
        padding-right: 0;
      }
      &:first-child {
        & > th, & > td {
          border-top: none;
          padding-top: 0;
        }
      }
      &:last-child {
        & > th, & > td {
          padding-bottom: 0;
        }
      }
    }
  }
}

.table-data-horizontal {
  margin: 0;
  font-size: 0.9rem;
  thead {
    tr {
      th {
        width: auto;
        border-top: none;
        padding-top: 0;
      }
    }
  }
  tbody {
    tr:last-child {
      td {
        padding-bottom: 0;
      }
    }
  }
  td, th {
    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      padding-right: 0;
    }
  }
}

.table-data-features {
  font-size: 0.9rem;
  margin-bottom: 0;
  & > thead {
    th {
      border-top: none;
      padding-top: 0;
      &:first-child {
        padding-left: 0;
      }
      &:last-child {
        padding-right: 0;
      }
    }
  }
  & > tbody {
      & > tr {
      & > td, & > th {
        &:first-child {
          padding-left: 0;
        }
        &:last-child {
          padding-right: 0;
        }
      }
      & > td {
        border-bottom: none;
        border-top: none;
      }
      & + tr > td {
        padding: 0;
        .price {
          padding: 0.75rem;
          margin-bottom: 0.75rem;
          background-color: $body-bg;
          border: 1px solid #e7eaf3;
          .table {
            margin: 0;
            thead {
              tr {
                th {
                  width: auto;
                  border-top: none;
                  padding-top: 0;
                }
              }
            }
            tbody {
              tr:last-child {
                td {
                  padding-bottom: 0;
                }
              }
            }
            td, th {
              &:first-child {
                padding-left: 0;
              }
              &:last-child {
                padding-right: 0;
              }
            }
          }
        }
      }
    }
  }
}

.route-product-details {
  .table-data {
    tbody {
      tr {
        th {
          vertical-align: top;
          &.short {
            width: 100px;
          }
        }
      }
    }
  }
  .asset-preview {
    width: 80px;
  }
}

.table-filters {
  margin: 0;
  font-size: 0.9rem;
  td, th {
    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      padding-right: 0;
    }
  }
}
