.route-faqs-search {
  .faq-search {
    h5 {
      text-align: center;
      font-weight: normal;
      margin: 5rem auto 1rem;
      font-size: 1.2rem;
    }

    .faq-search-form {
      max-width: 80%;
      position: relative;
      margin: 0 auto;

      .form-control {
        height: 3rem;
        font-size: 1.25rem;
        padding-right: 135px;
      }

      .btn {
        position: absolute;
        top: 5px;
        right: 5px;
      }
    }
  }

  .faq-default-results {
    margin-top: $grid-gutter-width * 3;
  }

  .faq-group {
    margin-bottom: $grid-gutter-width * 2;

    h5 {
      font-size: 1rem;
      font-weight: bold;
      padding-bottom: 5px;
      margin-left: 10px;
      margin-bottom: 10px;
      border-bottom: 1px solid rgba(black, 0.05);
      color: $primary;
    }

    ul {
      list-style: none;
      padding: 0;

      li {
        margin: 0.1rem 0;
        display: block;

        a {
          color: rgba(black, 0.55);
          font-size: 0.9rem;
          display: block;
          padding: 5px 10px 4px;
          transition: all .3s ease-in-out;
          border-radius: 4px;

          &:hover {
            background-color: $primary;
            text-decoration: none;
            color: white;
          }
        }

        &.hide {
          display: none;
        }
      }
    }

    .show-all {
      font-size: 0.8rem;
      display: block;
      margin-left: 10px;
      cursor: pointer;
      transition: all .3s ease-in-out;
      color: $primary;
    }
  }

  .faq-search-empty {
    margin-top: $grid-gutter-width * 3;
    color: rgba(black, 0.55);
    text-align: center;
    font-size: 1.2rem;

    b {
      font-weight: bold;
      color: $primary;
    }
  }

  .faq-search-results {
    margin-top: $grid-gutter-width * 3;

    h3 {
      font-weight: normal;
      color: rgba(black, 0.75);
      margin-bottom: $grid-gutter-width;
      font-size: 1.1rem;

      b {
        font-weight: bold;
        color: $primary;
      }
    }

    .search-results {
      .search-result {
        display: block;
        margin-bottom: $grid-gutter-width;
        transition: all .3s ease-in-out;

        &:hover {
          text-decoration: none;

          &>div {
            text-decoration: underline;
          }
        }

        h5 {
          font-size: 0.8rem;
          color: rgba(black, 0.45);
          text-transform: uppercase;
        }

        &>div {
          font-size: 1.2rem;
        }
      }
    }
  }

  .rdw-link-modal {
    height: 235px;
  }
}

.route-faqs-details {
  .box-body {
    &.faq {
      iframe {
        display: block;
        margin: $grid-gutter-width auto;
        width: 400px; // 16:9
        height: 225px;

        @include media-breakpoint-up(md) {
          width: 660px;
          height: 372px;
        }

        @include media-breakpoint-up(lg) {
          width: 900px;
          height: 506px;
        }
      }
    }
  }
}