.box {
  border: 1px solid #e7eaf3;
  box-shadow: 0 0.5rem 1.2rem rgba(189, 197, 209, 0.2);
  background-color: white;
  margin-bottom: $grid-gutter-width;
  border-radius: $border-radius;
  .box-body {
    padding: $grid-gutter-width / 3;
    @include media-breakpoint-up(md) {
      padding: $grid-gutter-width / 2;
    }
  }
  .box-header {
    padding: $grid-gutter-width / 4 $grid-gutter-width / 3;
    border-bottom: 1px solid #e7eaf3;
    @include media-breakpoint-up(md) {
      padding: $grid-gutter-width / 3 $grid-gutter-width / 2;
    }
    h3 {
      margin: 0;
      text-transform: uppercase;
      display: inline-block;
      font-size: 0.8em;
      color: #aaa;
    }
  }
  .box-empty {
    text-align: center;
    padding: $grid-gutter-width;
  }
}

.box-tabs {
  margin-bottom: $grid-gutter-width / 2;
  border: none;
  .nav-link {
    border-radius: 0.25rem;
    background-color: darken($body-bg, 10%);
    border-color: darken($body-bg, 10%);
    color: darken($body-bg, 60%);
    transition: all .3s;
    &.active,
    &.active:hover {
      background-color: $primary;
      border-color: $primary;
      color: white;
    }
    &:hover {
      color: $primary;
      background-color: darken($body-bg, 20%);
      border-color: darken($body-bg, 20%);
    }
    & + .nav-link {
      margin-left: 0.5rem;
    }
  }
}
.addCompany {
  position: absolute; 
  left: 50%;
  transform: translate(375px, 0);
  z-index:1;
}
.addCompanyText {
  position: absolute; 
  left: 50%;
  transform: translate(50px, 10px);
  z-index:1;
  font-size: smaller;
  color: #dc3545
}

.box-actions {
  margin-top: $grid-gutter-width / 2;
  text-align: right;
  .btn {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    & + .btn {
      margin-left: 0.5rem;
    }
  }
}
