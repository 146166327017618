.route-login,
.route-create-password,
.route-reset-password {
  .row {
    min-height: 100vh;
  }
  form {
    background: white;
    padding: $grid-gutter-width / 2;
    border-radius: 0.25rem;
    border: 1px solid #e7eaf3;
    box-shadow: 0 0.5rem 1.2rem rgba(189, 197, 209, 0.2);
    @include media-breakpoint-up(lg) {
      padding: $grid-gutter-width;
    }
  }
  .brand {
    max-width: 230px;
    height: 60px;
    display: block;
    margin: $grid-gutter-width auto;
    background-image: url('assets/logo-feda.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
}
