.quick-search {
  margin-bottom: $grid-gutter-width;
  .actions {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .action {
      display: block;
      text-align: center;
      width: 50%;
      margin-bottom: $grid-gutter-width / 2;
      transition: all .3s;
      border-radius: 5px;
      padding: 1rem 0.5rem;
      min-height: 7.5rem;
      background-color: darken($body-bg, 10%);
      border-color: darken($body-bg, 10%);
      color: $primary;
      &:hover {
        text-decoration: none;
        background-color: $primary;
        color: white;
      }
      &.empty {
        opacity: 0;
      }
      & + .action {
        margin-left: $grid-gutter-width / 2;
      }
      .fa {
        margin-top: 0.5rem;
        font-size: 2.25rem;
        opacity: 0.25;
      }
      .fa-stack {
        position: relative;
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
        display: inline-block;
        .fa {
          margin: 0;
          text-indent: -18px;
          &.fa-plus {
            font-size: 1.25rem;
            margin: 0;
            position: absolute;
            text-indent: 0;
            top: 8px;
            right: -16px;
          }
        }
      }
      strong {
        margin-top: 0.5rem;
        display: block;
        line-height: 1.2;
        font-size: 0.85rem;
        text-transform: none;
      }
      &:hover,
      &:focus,
      &:active:focus {
        outline: none;
        box-shadow: none;
      }
    }
  }
}
