.loading {
  text-align: center;

  svg {
    width: 100px;
    height: 100px;
    margin: 0 auto;
  }

  &.full-page {
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100vh + 50px);

    &>div {
      padding: $grid-gutter-width;
    }
  }

  &.small {
    svg {
      width: 30px;
      height: 30px;
      margin: 0 auto;

    }
  }

  &.xsmall {
    svg {
      width: 20px;
      height: 20px;
      margin: 0 auto;
    }
  }
}