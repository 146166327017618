.ReactTable {
  font-size: 0.9em;
  border: 1px solid #e7eaf3;
  box-shadow: 0 0.5rem 1.2rem rgba(189, 197, 209, 0.2);
  background-color: white;
  margin-bottom: $grid-gutter-width;
  .rt-table {
    .rt-thead {
      &.-header {
        box-shadow: none;
        border-bottom: 1px solid rgba(0,0,0,0.1);
        background-color: #F7F9FB;
      }
      .rt-th,
      .rt-td {
        &.-sort-asc {
          box-shadow: inset 0 3px 0 0 rgba(theme-color("primary"), 0.6);
        }
        &.-sort-desc {
          box-shadow: inset 0 -3px 0 0 rgba(theme-color("primary"), 0.6);
        }
      }
      .rt-th {
        font-weight: bold;
      }
    }
    .rt-tbody {
      .rt-tr {
        &.-even {
          background-color: #F4F6F9;
        }
      }
    }
  }
  .-loading.loading-table > div {
    top: 20%;
  }
}
.table-actions {
  padding-bottom: $grid-gutter-width / 3;
  @include media-breakpoint-up(md) {
    padding-bottom: $grid-gutter-width / 2;
  }
  .table-search {
    max-width: 260px;
  }
}
.table-total {
  text-align: right;
  padding-top: 10px;
}
.table-products {
  .table-total {
    text-align: left;
  }
}
