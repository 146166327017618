.route-product-new {
    .buttonContainer {
        display: flex;
        justify-content: flex-end;
    }

    .header {
        margin-top: 1rem;
        display: flex;
        justify-content: flex-end;
    }

    .divider {
        display: flex;
        height: 2px;
        background-color: rgba($color: #e7eaf3, $alpha: 1.0);
        margin-top: 1rem;
        margin-bottom: 1rem;
    }

    .box {
        &.min {
            max-height: 45px;
        }

        &.max {
            max-height: 10000px;
        }
    }

    .iconButton {
        float: right;
        padding: 0;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
    }

    .buttonContainer {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        padding-right: 1rem;

    }

    .exceptions {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        margin-bottom: 2rem;
        padding-left: 2rem;
    }

    .exceptionsContainer {
        border: 1px solid #dc3545;
        border-radius: 6px;
        background-color: #fcf0f1;
        margin-right: 0.5rem;
        margin-bottom: 0.5rem;
    }

    .messageContainer {
        display: flex;
        flex-direction: column;
        margin-bottom: 0.5rem;

        label {
            margin: 0;
        }

        span {
            font-size: 0.75rem;
            padding-left: 0.25rem;
        }
    }

}