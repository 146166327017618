.navbar {
  background-color: white;

  .navbar-brand {
    background-image: url('assets/logo-feda.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    white-space: nowrap;
    text-indent: 100%;
    overflow: hidden;
    width: 5.5rem;
  }

  .user {
    vertical-align: middle;
    display: inline-block;
    font-weight: bold;
    color: $body-color;
    .btn {
      margin-left: $grid-gutter-width / 2;
      font-size: 0.8rem;
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }

}
