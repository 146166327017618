.actions {
    .iconDiv {
        display: inline-block;
        cursor: pointer;
        margin-left: 0.5rem;
        margin-right: 0.5rem;

        .positive {
            color: #FFAB3D
        }

        .delete {
            color: #ff0000
        }
    }

    div[disabled] {
        opacity: 0.5;
        cursor: default;
    }
}